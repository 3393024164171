import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { loginStore, eventStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import jwtDecode, { JwtPayload } from "jwt-decode";
import auth from "./auth";
import dashboard from "./dashboard";
import login from "./login";
import onboarding from "./onboarding";
import oneclick from "./oneclick";
import sharing from "./sharing";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...auth,
  ...dashboard,
  ...login,
  ...onboarding,
  ...oneclick,
  ...sharing,
  {
    path: "*",
    meta: { title: "Page Not Found" },
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _from, next) => {
  function redirect() {
    if (to.name) {
      loginStore.mutations.setAfterAuth(to);
    }

    Vue.$cookies.remove("token");
    userStore.mutations.clearCurrentUser();

    next({ name: "login" });
  }

  if (to.meta?.authenticate) {
    const token = Vue.$cookies.get("token");

    if (!token) {
      return redirect();
    }

    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const exp = (decoded.exp || 0) * 1000;

      if (exp <= Date.now()) {
        return redirect();
      }
    } catch (err) {
      return redirect();
    }
  }

  // Remove trailing slash from urls. Eg /signup/ => /signup
  if (to.path.endsWith("/")) {
    return next({ path: to.path.slice(0, -1) });
  }

  next();
});

router.afterEach((to) => {
  eventStore.actions.record({
    name: EVENTS.PAGE.PAGEVIEW,
    data: {
      page: to.path,
      queryParams: to.query,
      name: to.name,
      pageWidth: Math.max(
        document.body.clientWidth || 0,
        window.innerWidth || 0
      ),
      pageHeight: Math.max(
        document.body.clientHeight || 0,
        window.innerHeight || 0
      ),
    },
  });
});

export default router;
