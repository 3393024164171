import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import VCalendar from "v-calendar";
import VueMeta from "vue-meta";
import { ZXCVBNResult } from "zxcvbn";
import axios, { AxiosError } from "axios";
import ldGet from "lodash/get";
import ldSet from "lodash/set";
import has from "lodash/has";
import router from "@/router";
import { eventStore, loginStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import AutoLogout from "@/plugins/autologout";
import Zendesk, { type ZendeskConfig } from "@/plugins/zendesk";
import * as Sentry from "@sentry/vue";
// @ts-ignore
import { modalManager } from "bootstrap-vue/esm/components/modal/helpers/modal-manager";
import PiwikPro, { PiwikProConfig } from "./plugins/piwikpro";

function getCookieDomain() {
  const hostname = window.location.hostname;
  let cookieDomain;

  // No "config" way of checking this so we need to rely on hostname
  if (hostname.endsWith("privacy.com")) {
    cookieDomain = ".privacy.com";
  }

  return cookieDomain;
}

function getPiwikContainerId() {
  let containerId = "21e5a2fe-eb06-4b61-8d6d-e11edeb7f9a6"; // development;

  if (window.location.hostname.endsWith("privacy.com")) {
    containerId = "b1731a03-2076-42ac-b00e-c72002f85000"; // production
  }

  return containerId;
}

Vue.use(BootstrapVue, {
  BToast: {
    toaster: "b-toaster-bottom-left",
    toastClass: "p-toast",
    autoHideDelay: 3000,
  },
});

Vue.use(VueCookies, {
  secure: window.location.protocol === "https:",
  domain: getCookieDomain(),
});
Vue.use(VCalendar);
Vue.use(VueMeta);
Vue.use(AutoLogout);
Vue.use(Zendesk, {
  key: "9a8c1380-ab1f-4b87-afdd-03f6062c475c",
  settings: {
    webWidget: {
      chat: {
        suppress: true,
      },
    },
  },
} as ZendeskConfig);
Vue.use(PiwikPro, {
  containerId: getPiwikContainerId(),
  containerUrl: "https://privacy.piwik.pro",
} as PiwikProConfig);

declare global {
  interface Window {
    rtl?: number;
    zxcvbn?: (password: string) => ZXCVBNResult;
  }
}

// This is a hack to fix a bug in bootstrap-vue where the modal manager
// is not getting the proper baseZIndex in Duck Duck Go's browser and more
// recently some major browsers (according to bug reports).
const origGetBaseZIndex = modalManager.getBaseZIndex;
modalManager.getBaseZIndex = function () {
  if (this.baseZIndex === -1 || this.baseZIndex === undefined) {
    this.baseZIndex = null;
  }

  return origGetBaseZIndex.call(this);
};

const vm = new Vue();

axios.interceptors.request.use((config) => {
  const isOutsideResource = !!config.url?.startsWith("http");
  if (!isOutsideResource && Vue.$cookies.get("token")) {
    config.headers.Authorization = "Bearer " + Vue.$cookies.get("token");
  }
  return config;
});

axios.interceptors.request.use((config) => {
  if (Vue.$cookies.get("token") && config.auth) {
    config.headers.Authorization = "Bearer " + Vue.$cookies.get("token");
  }
  return config;
});

// Anti-fraud properties that are intentionally vague.  Essentially we are doing a few things,
// at bank connection, to sniff out proxy users.  First, the add-funding controller is measuring
// latency to our web server from the client perspective.  Once it is done measuring it's
// sent up with the next API call via the request headers. We later
// compare latency to a measurement on the server. If radicially different, then we can assume
// the client is using a proxy or VPN.
//
// Secondly, we load the bank logos via cdn.privacy.com that will resolve to an edge node nearest
// the client (possibly behind a proxy).  The edge node will sent a cookie called 'Etag' that our
// JS looks for and will ship up to the API along with the latency measurement. We have to use a
// cookie because that is the only way another subdomain (cdn.privacy.com) can communicate
// back to the app.
axios.interceptors.request.use((config) => {
  if (has(window, "rtl") && Vue.$cookies.get("ETag")) {
    config.headers = config.headers || {};
    config.headers["x-rtl"] = window.rtl;
    config.headers["x-etag"] = Vue.$cookies.get("ETag");

    // And remove the evidence
    delete window.rtl;
    Vue.$cookies.remove("ETag");
  }
  return config;
});

// redirect to login if token is expired (401 error)
const authInterceptor = (error: AxiosError) => {
  const { currentRoute } = router;

  if (error.response?.status === 401) {
    // remove any stale tokens
    Vue.$cookies.remove("token");
    Vue.$cookies.remove("token", undefined, "privacy.com");

    if (currentRoute.meta?.authenticate) {
      if (currentRoute.name) {
        loginStore.mutations.setAfterAuth(currentRoute);
      }

      userStore.mutations.clearCurrentUser();
      // Only redirect to login if the state requires authentication
      router.replace({ name: "login" });
    }
  }

  return Promise.reject(error);
};

// prompt user to upgrade subscription if subscription is required
const subscriptionRequiredInterceptor = (error: AxiosError) => {
  const subscriptionRequired = ldGet<AxiosError, any>(
    error,
    "response.data.subscriptionRequired"
  );

  if (subscriptionRequired) {
    const currentUser = userStore.actions.getCurrentUser();

    currentUser.then(() => {
      const title = "Upgrade Required";
      const message = "Please visit the Pricing page to upgrade.";
      const okTitle = "See Plans";
      const nextState = "pricing";

      vm.$bvModal
        .msgBoxConfirm(message, {
          contentClass: "shadow confirm-content",
          centered: true,
          headerClass: "justify-content-center p-0",
          bodyClass: "text-center confirm-body",
          footerClass: "justify-content-center pt-0",
          cancelVariant: "light",
          title,
          okTitle,
          cancelTitle: "Cancel",
        })
        .then((confirmed: boolean) => {
          if (confirmed) {
            eventStore.actions.record({
              name: EVENTS.CTA.CLICKED,
              data: {
                buttonContext: "Subscription Required Prompt",
                buttonName: okTitle,
              },
            });
            router.push(nextState);
          }
        });

      eventStore.actions.record({
        name: EVENTS.MODAL.VIEWED,
        data: {
          modalName: "Subscription Required Prompt",
        },
      });
    });

    return Promise.reject(error);
  }

  return Promise.reject(error);
};

const unknownErrorInterceptor = (error: AxiosError) => {
  if (!error.response?.data.message && error.response) {
    ldSet(
      error,
      "response.data.message",
      ldGet<AxiosError, any>(error, "data.message") ||
        "Uh oh! Something went wrong, please try again."
    );
  } else if (!error.response) {
    // network error
    ldSet(
      error,
      "response.data.message",
      "Uh oh! Something went wrong, please try again."
    );
    Sentry.captureException(error);
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return authInterceptor(error)
      .catch(subscriptionRequiredInterceptor)
      .catch(unknownErrorInterceptor);
  }
);
