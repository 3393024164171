<template>
  <div :class="['svg-icon', ...getClassList]">
    <img :src="src" alt="" />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { cdnUrl } from "@/lib/cdn";

@Component
export default class SVGIcon extends Vue {
  @Prop() icon!: string;
  @Prop() uri!: string;
  @Prop({ default: 20 }) size?: number;

  get src() {
    let uri = this.uri;

    if (uri?.startsWith("/assets/images")) {
      uri = uri.replace("/assets", "");
    }

    return cdnUrl(uri || `/images/icons/${this.icon}-${this.size}.svg`);
  }

  get getClassList() {
    const classList = [];
    const sizeClass = `-size-${this.size}`;
    classList.push(sizeClass);

    return classList;
  }
}
</script>
<style lang="scss" scoped>
.svg-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  vertical-align: middle;

  &.-size-40 {
    height: 40px;
    width: 40px;
  }

  &.-size-80 {
    height: 80px;
    width: 80px;
  }
}
</style>
